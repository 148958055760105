



<template>

    <Section :options="computedOptions.sectionOptions" class="contact-details">

          <div class="row">

              <div class="col-md-auto details col-padding">

                  <h3 class="font-body fw-normal ls1">{{ $cms.utils.getComputedLabel(computedLabels.title) }}</h3>

                  <div class="contact">
                      <address v-if="computedOptions.address">
                          <strong>{{computedOptions.address.name ? computedOptions.address.name : computedOptions.name}}</strong><br>
                          {{computedOptions.address.addressLines[0]}}<br>
                          {{computedOptions.address.postalCode}} {{computedOptions.address.city}}<br>
                          <span v-if="computedOptions.address.note">{{ computedOptions.address.note }}</span>
                      </address>
                      <div>
                          <span v-if="computedOptions.phone"><strong>{{ $cms.utils.getComputedLabel(computedLabels.phone) }} </strong> {{computedOptions.phone}}<br/></span>
                          <span v-if="computedOptions.fax"><strong>{{ $cms.utils.getComputedLabel(computedLabels.fax) }} </strong> {{computedOptions.fax}}<br/></span>
                          <span v-if="computedOptions.email"><strong>{{ $cms.utils.getComputedLabel(computedLabels.email) }} </strong> {{computedOptions.email}}<br/></span>
                          <span v-if="computedOptions.web"><strong>{{ $cms.utils.getComputedLabel(computedLabels.web) }} </strong> <a :href="computedOptions.web" target="_blank">{{computedOptions.web}}</a></span>
                      </div>
                      <div v-if="computedOptions.contacts" class="contacts">
                        <div v-for="(contact, index) in computedOptions.contacts" :key="index + '-contact'">
                          <strong>{{ contact.firstName }} {{ contact.lastName }}</strong><br/>
                          <span v-if="contact.phones"><strong>{{ $cms.utils.getComputedLabel(computedLabels.phone) }} </strong> {{contact.phones[0].formatted}}<br/></span>
                          <span v-if="contact.emails"><strong>{{ $cms.utils.getComputedLabel(computedLabels.email) }} </strong> {{contact.emails[0].email}}<br/></span>
                        </div>
                      </div> 
                  </div>

                  <SocialLinks v-if="computedOptions.socialMedias" :options="{iconClass:'si-small si-text-color', socialMedias: computedOptions.socialMedias}" />

                  <div v-if="computedOptions.identifiers" class="identifiers clearfix">
                    <span v-if="computedOptions.identifiers && computedOptions.identifiers.siret">
                        <strong>{{$t('shop.shop.info.identifiers.siret')}}</strong> {{computedOptions.identifiers.siret}}<br/>
                    </span>
                    <span v-if="computedOptions.vatNumber">
                        <strong>{{$t('shop.shop.info.vatNumber')}}</strong> {{computedOptions.vatNumber}}<br/>
                    </span>
                  </div>

              </div>

              <div class="col-md map-container">
                <Map :options="getMapOptions()" :labels="getMapLabels()" />
              </div>

          </div>

    </Section>
    
</template>

<style>


.contact-details .row:first-child {
  margin-top:0px;
}

.contact-details .row {
  margin-top:40px;
}

.contact-details .map {
  min-height:200px;
  height:100%;
}

.contact-details .details .contact address {
  margin-bottom:20px;
}

.contact-details .details .contact address span {
  display: inline-block;
  padding-top: 5px;
}

.contact-details .details .contact .contacts {
  margin-top:30px;
}

.contact-details .details .contact .contacts > div {
  margin-top:10px;
}

@media (max-width: 767.98px) {
  .contact-details .details {
    padding:3rem;
  }

  .contact-details .details .contact,
  .contact-details .details .contact .contacts {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-details .details .contact address,
  .contact-details .details .contact > div,
  .contact-details .details .contact .contacts > div {
    flex: 1 0 auto;
    margin-right:30px;
  }

  .contact-details .details .contact .contacts {
    flex:auto;
    margin-right:0px;
    margin-top:20px;
  }

  .contact-details .map {
    margin-top:20px;
  }
}

.contact-details .identifiers, .contact-details .social-medias {
  margin-top:20px;
}
    

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, Ref, ref } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import Map from './Map.vue';
import { SocialMedias } from './social/types';

/** @cmsOptions */
export interface ContactDetailsOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
  address?:any
  contacts?:any
  identifiers?:any
  /** @cmsType CmsText */
  phone?:CmsText
  /** @cmsType CmsText */
  fax?:CmsText
  /** @cmsType CmsText */
  email?:CmsText
  /** @cmsType CmsText */
  web?:CmsText
  /** @cmsType CmsText */
  vatNumber?:CmsText
  /** @cmsType CmsInterface */
  socialMedias?:SocialMedias;
}

/** @cmsLabels */
export interface ContactDetailsLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  phone?:CmsLabel;
  /** @cmsType CmsLabel */
  fax?:CmsLabel;
  /** @cmsType CmsLabel */
  email?:CmsLabel;
  /** @cmsType CmsLabel */
  web?:CmsLabel;
  /** @cmsType CmsLabel */
  mapMakerLabel?:CmsLabel;
}

export default defineComponent({
  props: {
      options: Object as PropType<ContactDetailsOptions>,
      labels: {
        type: Object as PropType<ContactDetailsLabels>,
        default: () => { return {} }
      }
  },
  components : {
    Section,
    Map
  },
  setup(props, context) {

    const app = getApp();
    const router = useRouter();
    const store = useStore();
    const cms = useCmsModule();

    const computedOptions:ContactDetailsOptions = {
        ...props.options
    };

    const computedLabels:ContactDetailsLabels = {
      title: {
        fr : "Nous trouver"
      },
      phone : {
        fr : "Téléphone:"
      },
      fax : {
        fr : "Fax:"
      },
      email : {
        fr : "E-Mail:"
      },
      web : {
        fr : "Web:"
      },
      ...props.labels
    }

    const getMapOptions = () => {
      if(computedOptions.address && 
          computedOptions.address.lat && 
          computedOptions.address.lng) {
        return {
          center: {
            lat: computedOptions.address.lat,
            lng: computedOptions.address.lng
          },
          marker: {
            lat: computedOptions.address.lat,
            lng: computedOptions.address.lng
          }
        }
      }
      return {};
    }

    const getMapLabels = () => {
      return {
        marker: computedLabels.mapMakerLabel
      }
    }

    return {
      getMapOptions,
      getMapLabels,
      computedOptions: {
        ...computedOptions
      },
      computedLabels: {
        ...computedLabels
      }
    }
  }
    
})
</script>